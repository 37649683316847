'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider } from '@chakra-ui/react';
import { domMax, LazyMotion } from 'framer-motion';
import { useEffect } from 'react';

import { initAmplitude } from '@/amplitude/initAmplitude';
import { GoogleAnalytics } from '@/components/context/GoogleAnalytics';
import { theme } from '@/theme';

export function Providers({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    initAmplitude();
  }, []);

  return (
    <CacheProvider>
      <ChakraProvider theme={theme}>
        <LazyMotion features={domMax}>
          <GoogleAnalytics />
          {children}
        </LazyMotion>
      </ChakraProvider>
    </CacheProvider>
  );
}
