'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';

import { GA_MEASUREMENT_ID, existsGaId, pageview } from '@/utils/gtag';

export const GoogleAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!existsGaId) {
      return;
    }
    const url = `${pathname}${searchParams?.toString() ?? ''}`;
    pageview(url);
  }, [pathname, searchParams]);

  return (
    <>
      {existsGaId && (
        <>
          <Script
            id="ga-url"
            defer
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
            strategy="afterInteractive"
          />
          <Script
            id="ga-script"
            defer
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());    
              gtag('config', '${GA_MEASUREMENT_ID}');
            `,
            }}
            strategy="afterInteractive"
          />
        </>
      )}
    </>
  );
};
